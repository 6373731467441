<template>
  <div class="shop" style="font-size: 18px;background:rgb(246, 243, 243);height: 100%;padding-bottom: 60px">
    <div class="information">
      <div
        class="center"
        v-for="item in hzlist"
        :key="item.index"
        @click="zhList(item.BFaId)"
      >
        <div
          class="shopTitle van-ellipsis"
          style="padding-top: 0; padding-bottom: 0"
        >
          {{ item.Title }}
        </div>
        <div class="Content" style="padding: 8px 10px">
          <div style="display: inline-block;">
            <!-- <div style="font-size: 16px; color: #333; margin: 5px 0">
              <span style="margin-right: 20px"
                >户主：{{ item.Householder }}
                <i
                    class="iconfont icon-lianzufang"
                    style="color: red"
                    v-if="item.FamilyKind == 6"
                  ></i>
                </span
              >房间号：{{ item.BRNO }}
            </div> -->
            <div style="font-size: 16px; color: #333; margin: 5px 0">
                           <span style="margin-right: 20px"
                >户主：{{ item.Householder }}
                <i
                    class="iconfont icon-lianzufang"
                    style="color: red"
                    v-if="item.FamilyKind == 6"
                  ></i>
                </span>
            </div>
            <div style="font-size: 16px; color: #333; margin: 5px 0">
              <span>房间号：{{ item.BRNO }}</span>
            </div>
            <span class="centertime" style="color: #333; font-size: 16px">
              <span>家庭人数：{{ item.FamilySize }}</span>
            </span>
          </div>
          <div class="hidAddOn">
            <van-button
              round
              type="primary"
              size="mini"
              style="text-center: right; font-size: 14px"
              @click="add(item)"
              >新增家庭成员</van-button
            >
                        <van-button
              round
              type="info"
              size="mini"
              color="#617bfa"
              style="text-center: right; font-size: 14px"
              @click="updataXq(item)"
              >编辑</van-button
            >
          </div>
        </div>
      </div>
      <div class="center" v-for="item in list" :key="item.index">
        <div
          class="shopTitle van-ellipsis"
          style="padding-top: 0; padding-bottom: 0"
        >
          {{ item.Title }}
        </div>
        <div class="Content" style="padding: 8px 10px">
          <div
            style="display: inline-block;"
            @click="jtXq(item.HId)"
          >
            <div style="font-size: 16px; color: #333; margin: 5px 0">
              <span style="margin-right: 20px">姓名：{{ item.Name }}
                <i
                    class="iconfont icon-junren"
                    style="color: red"
                    v-if="item.Veterans == '是'"
                  ></i>
                   <i
                    class="iconfont icon-dangyuan"
                    style="color: red"
                    v-if="item.PartyMember == '是'"
                  ></i>
                  <i
                    class="iconfont icon-canjirenzheng"
                    style="color: red"
                    v-if="item.Disabled == '是'"
                  ></i>
                </span
              >
            </div>
            <div class="centertime" style="color: #333; font-size: 16px; margin: 5px 0">
              <span>与户主关系：{{ item.RelationHouserName }}</span>
            </div>
            <span class="centertime" style="color: #333; font-size: 16px; margin: 5px 0">
              <span>身份证号：{{ item.IdNumber }}</span>
            </span>
            <div class="centertime" style="color: #333; font-size: 16px; margin: 5px 0">
              <span>地址：{{ item.Address }}</span>
            </div>
            <!-- <span style="color: #333; font-size: 16px;display: block;">
               <i
                    class="iconfont icon-junren"
                    style="color: red"
                    v-if="item.Veterans == '是'"
                  ></i>
            </span> -->
          </div>
          <div class="hidAddOn">
            <van-button
              round
              type="info"
              size="mini"
              style="text-center: right; font-size: 14px"
              color="#617bfa"
              @click="jtbianji(item.HId)"
              >编辑</van-button
            >
            <van-button
              round
              type="danger"
              size="mini"
              style="text-center: right; font-size: 14px"
              @click="delet(item.HId)"
              >删除</van-button
            >
          </div>
        </div>
        <!-- <div style="text-align:right;margin-right:10px;"><van-button round type="info" size="mini" style="text-center:right;margin-right:10px;margin-bottom:10px;">编辑</van-button></div> -->
      </div>
        <div style="">
        <div
          style="
            width: 95%;
            bottom: 0;
            display: grid;
            position: fixed;
            padding: 10px;
          "
        >
          <van-button
            round
            type="info"
            size="normal"
            style="text-center: right;font-size: 16px"
            block
            @click="addXq()"
            color="#617bfa"
            >新增</van-button
          >
        </div>
      </div>
      <!-- <van-popup v-model:show="show" position="top" :style="{ height: '30%' }" /> -->
      <!-- 没有更多数据了 -->
      <div v-if="hidden" class="hidden">───── 没有更多数据了 ─────</div>
    </div>
  </div>
</template>
<script>
import {
  GetBuildFamilyPage,
  GetResidentListByFamily,
  DeleteResident,
} from "@/api/wangge";
import { getwgToken, setwgToken } from "@/utils/auth";
import { Col } from "vant";
import { Dialog } from "vant";
import Vue from "vue";
import { Toast } from "vant";
Vue.use(Toast);
export default {
  data() {
    return {
      riKind: 0, //tab栏
      hzlist: {}, //户主
      list: {},
      listfrom: {
        page: 1,
        limit: 10,
        brId: "",
      }, //查询传参
      search: "", //搜索内容
      hidden: true, //没有更多数据了
      loading: false, //加载
      finished: false, //数据完全加载结束为 true
      total: undefined, //数据条数
      show: false,
      wushuju: false,
      zhfrom: {
        page: 1,
        limit: 10,
        bFaId: "",
      },
      ldlist: {},
    };
  },
  created() {
    this.getList();
  },
  methods: {
    // 获取住户列表
    getList() {
      console.log(this.$route.params);
      //   this.listfrom.riKind = this.riKind;
      this.listfrom.brId = this.$route.params.Id;
      this.listfrom.accToken = getwgToken();
      GetBuildFamilyPage(this.listfrom)
        .then((res) => {
          if (res.data.code == 0) {
            if (res.data.count == 0) {
              this.hzlist = {};
            } else {
              this.hzlist = res.data.data;
              console.log(this.hzlist[0]);
              this.zhList(this.hzlist[0].BFaId);
              this.zhid = this.hzlist[0].BFaId
              //   for (var i = 0; i < this.dylist.length; i++) {
              //     var item = this.dylist[i].IssTime;
              //     item = this.dataFormat(item);
              //     this.dylist[i].IssTime = item;
              //     console.log();
              //     this.fwList()
              //   }
            }
          }
        })
        .catch(() => {});
    },
    // 获取家庭成员列表
    zhList(bFaId) {
      console.log(bFaId);
      //   this.listfrom.riKind = this.riKind;
      this.zhfrom.bFaId = bFaId;
      this.zhfrom.accToken = getwgToken();
      GetResidentListByFamily(this.zhfrom)
        .then((res) => {
          if (res.data.code == 0) {
            console.log(res.data.data.length);
            if (res.data.data.length == 0) {
              // this.hidden = true;
              this.finished = true;
              this.list = {};
            } else {
              this.list = res.data.data;
              this.total = res.data.count;
              this.finished = true;
              // this.hidden = false;
              for (var i = 0; i < this.list.length; i++) {
                var item = this.list[i].IssTime;
                item = this.dataFormat(item);
                this.list[i].IssTime = item;
              }
            }
          }
        })
        .catch(() => {});
    },
    add: function (row) {
      console.log(row);
      this.itemmark = JSON.stringify(row.BFaId);
      console.log(this.itemmark);
      this.$router.push({
        name: "jtbianji",
        query: { BFaId: this.itemmark, add: 1, BRId: row.BRId },
      });
    },
    jtbianji: function (HId) {
      console.log(HId);
      this.itemmark = JSON.stringify(HId);
      this.$router.push({
        name: "jtbianji",
        query: { HId: this.itemmark, add: 2 },
      });
    },
    delet: function (row) {
      console.log(row);
      Dialog.confirm({
        title: "提示",
        message: "确定要删除该条住户信息",
      })
        .then(() => {
          // on confirm
          console.log(312);
          DeleteResident({ accToken: getwgToken(), HId: row })
            .then((res) => {
              console.log(res.data.code);
              if (res.data.code == 0) {
                Toast.success("删除成功");
                this.getList();
              } else{
                Toast.fail(res.data.msg);
              }
            })
            .catch(() => {});
        })
        //
        .catch(() => {
          // on cancel
          console.log(123);
        });
    },
    jtXq: function (HId) {
      console.log(HId);
      this.itemmark = JSON.stringify(HId);
      this.$router.push({
        name: "chengyuanXq",
        query: { HId: this.itemmark },
      });
    },
    addXq: function () {
      console.log(this.zhid);
      this.itemmark = JSON.stringify(this.zhid);
      this.$router.push({
        name: "roomAdd",
        query: { bfaId: this.itemmark,add:1, },
      });
    },
    updataXq: function () {
      console.log(this.zhid);
      this.itemmark = JSON.stringify(this.zhid);
      this.$router.push({
        name: "roomAdd",
        query: { bfaId: this.itemmark,add:2, },
      });
    },
    showPopup(RId) {
      console.log(RId);
      this.show = true;
      this.ldList(RId);
    },
    onClose() {
      this.setData({ show: false });
    },
    // 下拉刷新
    // onLoad() {
    //   console.log(123);
    //   this.listfrom.page = this.listfrom.page + 1;
    //   this.getList();
    // },
    // tan点击切换事件
    onClick(e) {
      console.log(123123);
      console.log(e);
      this.fwList(e);
    },
    // 搜索
    onSearch() {
      this.listfrom.page = 1;
      this.getList();
    },
    // 时间格式化
    dataFormat(value) {
      var year = value.substr(0, 4);
      var month = value.substr(5, 2);
      var day = value.substr(8, 2);
      var hour = value.substr(11, 2);
      var min = value.substr(14, 2);
      var second = value.substr(17, 2);
      return (
        year + "-" + month + "-" + day + " " + hour + ":" + min + ":" + second
      );
    },
  },
};
</script>
<style>
.shopTitle {
  color: #333;
  padding: 10px;
}
.shop .information .center {
  position: relative;
  border-bottom: none;
  padding: 0px;
  border-radius: 5px;
}
.shop .center {
  width: 92%;
  margin: 10px auto;
  background: white;
}
.hidden {
  text-align: center;
  font-size: 12px;
  color: #ccc;
  margin: 10px 0px 25px 0px;
}
.hidAddOn {
  position: relative;
  text-align: right;
  margin-right: 10px;
}
.hidAddOn button {
  display: inline-block;
  padding: 12px;
}
</style>